import {
  MDBCol,
  MDBRow
} from 'mdb-react-ui-kit';

export default function Placeholder() {
  
  return (
    <>
      <MDBRow className='vh-100 text-center' center>
          <MDBCol center>
              <p className='placeholder-glow text-center' aria-hidden='true'>
                  <span className='placeholder col-12 placeholder-lg'>Loading content</span><br />
                  <span className='placeholder col-12 placeholder-lg'>Please standby, you will enjoy it.</span>
              </p>
          </MDBCol>
      </MDBRow>
  </>
  );
}