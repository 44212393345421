import React, { useState, lazy }  from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
// import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';
import ReactiveButton from 'reactive-button';
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBTypography
  } from 'mdb-react-ui-kit';
import styled from "styled-components";
import { keyframes } from "styled-components";

import Placeholder from '../../components/shared/Placeholder';
import hero from '../../images/store-234.jpg'
const FooterSlim = lazy(() => import('../../components/layout/FooterSlim'));

const gradient = keyframes`
    {
    0% {
    background-position: 0 50%;
    }
    50% {
    background-position: 100% 50%;
    }
    100% {
    background-position: 0 50%;
    }}
`;

const StyledHeading = styled.h1`
    font-family: 'Abril Fatface', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 5rem;
    animation: ${gradient} 5s ease-in-out infinite;
    background: linear-gradient(to bottom, #fceabb 0%,#fccd4d 50%,#f8b500 51%,#fbdf93 100%);
    -webkit-background-clip: text;
    background-size: 300%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    text-shadow: none;
    line-height: .9em;
`;

const StyledHero = styled.div`
    img {
        --b: 8px;  /* border thickness*/
        --s: 60px; /* size of the corner*/
        --g: 14px; /* the gap*/
        --c: #EDC951; 
        
        padding: calc(var(--b) + var(--g));
        background-image:
        conic-gradient(from  90deg at top    var(--b) left  var(--b),#0000 25%,var(--c) 0),
        conic-gradient(from -90deg at bottom var(--b) right var(--b),#0000 25%,var(--c) 0);
        background-position:
        var(--_p,0%) var(--_p,0%),
        calc(100% - var(--_p,0%)) calc(100% - var(--_p,0%));
        background-size: var(--s) var(--s);
        background-repeat: no-repeat;
        cursor: pointer;
        transition: 
        background-position .3s var(--_i,.3s), 
        background-size .3s calc(.3s - var(--_i,.3s));
    }
    img.alt {
        background-image:
        conic-gradient(from 180deg at top    var(--b) right var(--b),#0000 25%,var(--c) 0),
        conic-gradient(from   0deg at bottom var(--b) left  var(--b),#0000 25%,var(--c) 0);
        background-position:
        calc(100% - var(--_p,0%)) var(--_p,0%),
        var(--_p,0%) calc(100% - var(--_p,0%));
    }
    img:hover {
        background-size: calc(100% - var(--g)) calc(100% - var(--g));
        --_p: calc(var(--g)/2);
        --_i: 0s;
    }
`;

export const HomeStep3 = () => {
    const apiBackend = process.env.REACT_APP_API_SERVICE;
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [contactEmail, setContactEmail] = useState('');
    let { storeId } = useParams();
    const [isLoading,] = useState(false);

    const notify = (msg) => toast(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        width: '100%'
    });

    const submitStep3 = async () => {
        history.push({
            pathname: '/homeResult',
            state: { 
                options: location.state
            }
        })
    }

    const createDesign = async () => {
        setLoading(true);
        await fetch(apiBackend + `/wfh/add`, {
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              state: location.state
            })
        })
        .then((response) => {
            if (response.status === 201) {
                let msg = "Thanks for creating!";
                notify(msg);
            }
            else if (response.status === 400) {
                let msg = response.statusText ? response.statusText : "Uh oh: " + response.status;
                notify(msg);
            }
            else {
                let msg = response.statusText ? response.statusText : "Something happened: " + response.status;
                notify(msg);
            }
            submitContact();
            submitStep3();
        });
    };

    const submitContact = async () => {
        await fetch("https://entityco.onrender.com/api/crm/add", {
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: contactEmail,
              listId: 4
            })
        })
        .then((response) => {
            // setLoading(false);
            if (response.status === 302) {
                let msg = response.statusText ? response.statusText : "You have already joined!";
                notify(msg);
            }
            else if (response.status === 201) {
                let msg = response.statusText ? response.statusText : "Thanks for joining!";
                notify(msg);
            }
            else if (response.status === 400) {
                let msg = response.statusText ? response.statusText : "Uh oh: " + response.status;
                notify(msg);
            }
            else {
                let msg = response.statusText ? response.statusText : "Something happened: " + response.status;
                notify(msg);
            }
            setContactEmail('');
        });
    };

    // Set default
    if (!storeId) {
        storeId = 234;
    }

    if (isLoading) {
        return (
            <Placeholder />
        );
    }

    return (
        <>
            <MDBContainer>
                <MDBRow className='vh-100'>
                    <MDBCol size='12' md='6' className='px-4' center>
                        <StyledHeading>That's the way</StyledHeading>
                        <div className="flex flex-wrap items-center justify-center w-full">
                            <MDBTypography tag='div' className='display-5' style={{
                                fontFamily: 'Bebas Neue',
                                lineHeight: 1.5
                            }}>
                                Save your template
                            </MDBTypography>
                            <form className="flex flex-col w-full lg:w-80">
                                <MDBTypography tag='div' className='display-6' style={{
                                    fontFamily: 'Bebas Neue',
                                    lineHeight: 1.3
                                }}>3. Enter your email address</MDBTypography>
                                <input
                                    className="block w-full rounded-md border-0 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={contactEmail}
                                    placeholder='me@example.com'
                                    onChange={e => setContactEmail(e.target.value)}
                                    required
                                    size="lg"
                                    autoComplete="off"
                                />
                                <ReactiveButton
                                    idleText='Save and show my result!'
                                    loadingText="Loading"
                                    successText="Done"
                                    onClick={createDesign}
                                    color="green"
                                    disabled={loading || !contactEmail}
                                    size={'large'}
                                    shadow={true}
                                    rounded={true}
                                />
                            </form>
                        </div>
                    </MDBCol>
                    <MDBCol size='12' md='6' center>
                        <StyledHero>
                            <img 
                                src={hero} 
                                alt='Home Store'
                                className='mt-4'
                                width='100%'
                                height='auto'
                            />
                        </StyledHero>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <FooterSlim />
            <ToastContainer />
        </>
    );
}