import React, { useState, lazy }  from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Select from "react-select";
import 'react-toastify/dist/ReactToastify.css';
import ReactiveButton from 'reactive-button';
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBTypography
  } from 'mdb-react-ui-kit';
import styled from "styled-components";
import { keyframes } from "styled-components";

import Placeholder from '../../components/shared/Placeholder';
import hero from '../../images/store-234.jpg'
const FooterSlim = lazy(() => import('../../components/layout/FooterSlim'));

const gradient = keyframes`
    {
    0% {
    background-position: 0 50%;
    }
    50% {
    background-position: 100% 50%;
    }
    100% {
    background-position: 0 50%;
    }}
`;

const StyledHeading = styled.h1`
    font-family: 'Abril Fatface', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 5rem;
    animation: ${gradient} 5s ease-in-out infinite;
    background: linear-gradient(to bottom, #fceabb 0%,#fccd4d 50%,#f8b500 51%,#fbdf93 100%);
    -webkit-background-clip: text;
    background-size: 300%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    text-shadow: none;
    line-height: .9em;
`;

const StyledHero = styled.div`
    img {
        --b: 8px;  /* border thickness*/
        --s: 60px; /* size of the corner*/
        --g: 14px; /* the gap*/
        --c: #EDC951; 
        
        padding: calc(var(--b) + var(--g));
        background-image:
        conic-gradient(from  90deg at top    var(--b) left  var(--b),#0000 25%,var(--c) 0),
        conic-gradient(from -90deg at bottom var(--b) right var(--b),#0000 25%,var(--c) 0);
        background-position:
        var(--_p,0%) var(--_p,0%),
        calc(100% - var(--_p,0%)) calc(100% - var(--_p,0%));
        background-size: var(--s) var(--s);
        background-repeat: no-repeat;
        cursor: pointer;
        transition: 
        background-position .3s var(--_i,.3s), 
        background-size .3s calc(.3s - var(--_i,.3s));
    }
    img.alt {
        background-image:
        conic-gradient(from 180deg at top    var(--b) right var(--b),#0000 25%,var(--c) 0),
        conic-gradient(from   0deg at bottom var(--b) left  var(--b),#0000 25%,var(--c) 0);
        background-position:
        calc(100% - var(--_p,0%)) var(--_p,0%),
        var(--_p,0%) calc(100% - var(--_p,0%));
    }
    img:hover {
        background-size: calc(100% - var(--g)) calc(100% - var(--g));
        --_p: calc(var(--g)/2);
        --_i: 0s;
    }
`;

export const HomeStep2 = () => {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    let { storeId } = useParams();
    const [isLoading,] = useState(false);
    const [selectedBudget, setSelectedBudget] = useState(null);

    const submitStep2 = async () => {
        setLoading(true);
        history.push({
            pathname: '/step3',
            state: { 
                option1: location.state.option1,
                option2: selectedBudget,
            }
        })
    }

    // Set default
    if (!storeId) {
        storeId = 234;
    }

    if (isLoading) {
        return (
            <Placeholder />
        );
    }

    const options_budget = [
        { value: 'small', label: 'Under $500', url: ''},
        { value: 'medium', label: '$500-$1000', url: '' },
        { value: 'large', label: 'Over $1000', url: '' },
    ]

    const handleChangeBudget = (selectedOption) => {
        setSelectedBudget(selectedOption);
    };

    return (
        <>
            <MDBContainer>
                <MDBRow className='vh-100'>
                    <MDBCol size='12' md='6' className='px-4' center>
                        <StyledHeading>Keep it coming</StyledHeading>
                        <div className="flex flex-wrap items-center justify-center w-full">
                            <MDBTypography tag='div' className='display-5' style={{
                                fontFamily: 'Bebas Neue',
                                lineHeight: 1.5
                            }}>
                                Tell us more
                            </MDBTypography>
                            <form className="flex flex-col w-full lg:w-80">
                                <MDBTypography tag='div' className='display-6' style={{
                                    fontFamily: 'Bebas Neue',
                                    lineHeight: 1.3
                                }}>2. Select a budget</MDBTypography>
                                <Select 
                                    options={options_budget} 
                                    onChange={handleChangeBudget}
                                    className='my-3'
                                    formatOptionLabel={item => (
                                        <div className="">
                                            <img 
                                                src={item.url ? item.url : 'https://placehold.co/50'} 
                                                alt={item.label} 
                                                height='100'
                                                width='100'
                                            />&nbsp;
                                            <span
                                                className=''
                                                style={{fontWeight: 'bold'}}
                                            >
                                                {item.label}
                                            </span>
                                        </div>
                                    )}
                                />
                                <ReactiveButton
                                    idleText='Show me more'
                                    loadingText="Loading"
                                    successText="Done"
                                    onClick={submitStep2}
                                    color="green"
                                    disabled={loading || !selectedBudget}
                                    size={'large'}
                                    shadow={true}
                                    rounded={true}
                                />
                            </form>
                        </div>
                    </MDBCol>
                    <MDBCol size='12' md='6' center>
                        <StyledHero>
                            <img 
                                src={hero} 
                                alt='Home Store'
                                className='mt-4'
                                width='100%'
                                height='auto'
                            />
                        </StyledHero>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <FooterSlim />
        </>
    );
}