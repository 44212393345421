import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { PrivateRoute } from "./auth/PrivateRoute";
import ScrollToTop from "./components/shared/ScrollToTop";
import { HomeStep2 } from "./pages/store/HomeStep2";
import { HomeStep3 } from "./pages/store/HomeStep3";
import { HomeResult } from "./pages/store/HomeResult";
const NavBar = lazy(() => import("./components/layout/NavBar"));

const HomeBuilder = lazy(() => import("./pages/cms/HomeBuilder"));
const HomePage = lazy(() => import("./pages/cms/HomePage"));
const AboutBuilder = lazy(() => import("./pages/cms/AboutBuilder"));
const NotFoundPage = lazy(() => import("./pages/cms/NotFoundPage"));
const PrivacyPage = lazy(() => import("./pages/cms/PrivacyPage"));
const TermsPage = lazy(() => import("./pages/cms/TermsPage"));
const PrizesPage = lazy(() => import("./pages/cms/PrizesPage"));
const EditorialsPage = lazy(() => import("./pages/cms/EditorialsPage"));
const CaseStudy = lazy(() => import("./pages/cms/CaseStudy"));

const EmailVerificationLandingPage = lazy(() =>
  import("./pages/auth/EmailVerificationLandingPage")
);
const ForgotPasswordPage = lazy(() =>
  import("./pages/auth/ForgotPasswordPage")
);
const LogInPage = lazy(() => import("./pages/auth/LogInPage"));
const SignUpPage = lazy(() => import("./pages/auth/SignUpPage"));
const PasswordResetLandingPage = lazy(() =>
  import("./pages/auth/PasswordResetLandingPage")
);
const PleaseVerifyEmailPage = lazy(() =>
  import("./pages/auth/PleaseVerifyEmailPage")
);

const BusinessInfoPage = lazy(() =>
  import("./pages/business/BusinessInfoPage")
);
const UserInfoPage = lazy(() => import("./pages/user/UserInfoPage"));
const UserProfilePage = lazy(() => import("./pages/user/UserProfilePage"));
const ForYouPage = lazy(() => import("./pages/user/ForYouPage"));
const UserResultsPage = lazy(() => import("./pages/user/UserResultsPage"));

const PrizeCreatePage = lazy(() => import("./pages/prizes/PrizeCreatePage"));
const PrizePage = lazy(() => import("./pages/prizes/PrizePage"));
const PrizesListPage = lazy(() => import("./pages/prizes/PrizesListPage"));
const PrizeEditPage = lazy(() => import("./pages/prizes/PrizeEditPage"));
const PrizeReportPage = lazy(() => import("./pages/prizes/PrizeReportPage"));

const EventsListPage = lazy(() => import("./pages/events/EventsListPage"));
const EventsResultsPage = lazy(() =>
  import("./pages/events/EventsResultsPage")
);
const EventPage = lazy(() => import("./pages/events/EventPage"));
const EventCreatePage = lazy(() => import("./pages/events/EventCreatePage"));
const EventSubmissionPage = lazy(() =>
  import("./pages/events/EventSubmissionPage")
);
const EventEditPage = lazy(() => import("./pages/events/EventEditPage"));
const EventReportPage = lazy(() => import("./pages/events/EventReportPage"));

const CatalogReportPage = lazy(() =>
  import("./pages/catalog/CatalogReportPage")
);
const CategoryPage = lazy(() => import("./pages/categories/CategoryPage"));
const CategoryCreatePage = lazy(() =>
  import("./pages/categories/CategoryCreatePage")
);
const CategoryEditPage = lazy(() =>
  import("./pages/categories/CategoryEditPage")
);
const CategoriesListPage = lazy(() =>
  import("./pages/categories/CategoriesListPage")
);
const CategoriesSelectPage = lazy(() =>
  import("./pages/categories/CategoriesSelectPage")
);

const BusinessReportPage = lazy(() =>
  import("./pages/catalog/BusinessReportPage")
);
const BusinessCreatePage = lazy(() =>
  import("./pages/business/BusinessCreatePage")
);
const BusinessEditPage = lazy(() =>
  import("./pages/business/BusinessEditPage")
);
const AvatarProfilePage = lazy(() => import("./pages/ai/AvatarProfilePage"));
const AvatarDirectory = lazy(() => import("./pages/ai/AvatarDirectory"));

const StorePage = lazy(() => import("./pages/store/StorePage"));
const HomeStore = lazy(() => import("./pages/store/HomeStore"));
const StoreMenuPage = lazy(() => import("./pages/store/StoreMenuPage"));
const OrderStatusPage = lazy(() => import("./pages/orders/OrderStatusPage"));

const SendPromptPage = lazy(() => import("./pages/ai/SendPromptPage"));
const ExperiencesPage = lazy(() => import("./pages/experiences/ExperiencesPage"));

export const Routes = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div className="vh-100 border d-flex align-items-center justify-content-center">
            <div className="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        }
      >
        <ScrollToTop>
          <Switch>
            <Route path="/" exact>
              <NavBar />
              <HomeBuilder />
            </Route>
            <Route path="/sendPrompt">
              <NavBar />
              <SendPromptPage />
            </Route>
            <Route path="/store/:storeId/order/:orderId">
              <StoreMenuPage />
            </Route>
            <Route path="/store/:storeId/orders/status/:orderId">
              <OrderStatusPage />
            </Route>
            <Route path="/store/123">
              <NavBar />
              <StorePage />
            </Route>
            <Route path="/store/234">
              <NavBar />
              <HomeStore />
            </Route>
            <Route path="/step2">
              <NavBar />
              <HomeStep2 />
            </Route>
            <Route path="/step3">
              <NavBar />
              <HomeStep3 />
            </Route>
            <Route path="/homeResult">
              <NavBar />
              <HomeResult />
            </Route>
            <Route path="/store/:storeId">
              <HomePage />
            </Route>
            <Route path="/profile/:username">
              <NavBar />
              <AvatarProfilePage />
            </Route>
            <Route path="/directory">
              <NavBar />
              <AvatarDirectory />
            </Route>
            <Route path="/u/:username">
              <NavBar />
              <UserProfilePage />
            </Route>
            <Route path="/user/:username">
              <NavBar />
              <UserProfilePage />
            </Route>
            <PrivateRoute path="/user" exact>
              <NavBar />
              <UserInfoPage />
            </PrivateRoute>
            <PrivateRoute path="/business" exact>
              <NavBar />
              <BusinessInfoPage />
            </PrivateRoute>
            <Route path="/verify-email/:verificationString">
              <NavBar />
              <EmailVerificationLandingPage />
            </Route>
            <Route path="/forgot-password">
              <NavBar />
              <ForgotPasswordPage />
            </Route>
            <Route path="/login">
              <NavBar />
              <LogInPage />
            </Route>
            <Route path="/reset-password/:passwordResetCode">
              <NavBar />
              <PasswordResetLandingPage />
            </Route>
            <Route path="/please-verify">
              <NavBar />
              <PleaseVerifyEmailPage />
            </Route>
            <Route path="/signup">
              <NavBar />
              <SignUpPage />
            </Route>
            <Route path="/about">
              <NavBar />
              <AboutBuilder />
            </Route>
            <Route path="/case-study">
              <NavBar />
              <CaseStudy />
            </Route>
            <Route path="/editorials">
              <NavBar />
              <EditorialsPage />
            </Route>
            <Route path="/prize">
              <NavBar />
              <PrizesPage />
            </Route>
            <Route path="/events/:eventId/report">
              <NavBar />
              <EventReportPage />
            </Route>
            <Route path="/events/:eventId">
              <NavBar />
              <EventPage />
            </Route>
            <Route path="/events">
              <NavBar />
              <EventsListPage />
            </Route>
            <Route path="/events-create">
              <NavBar />
              <EventCreatePage />
            </Route>
            <Route path="/events-submission">
              <NavBar />
              <EventSubmissionPage />
            </Route>
            <Route path="/events-edit/:eventId">
              <NavBar />
              <EventEditPage />
            </Route>
            <Route path="/search/events">
              <NavBar />
              <EventsResultsPage />
            </Route>
            <Route path="/prizes/:prizeCode/report">
              <NavBar />
              <PrizeReportPage />
            </Route>
            <Route path="/prizes/:prizeCode">
              <NavBar />
              <PrizePage />
            </Route>
            <Route path="/prizes">
              <NavBar />
              <PrizesListPage />
            </Route>
            <Route path="/prizes-create">
              <NavBar />
              <PrizeCreatePage />
            </Route>
            <Route path="/prizes-edit/:prizeCode">
              <NavBar />
              <PrizeEditPage />
            </Route>
            <Route path="/catalog">
              <NavBar />
              <CatalogReportPage />
            </Route>
            <Route path="/category/:categoryCode">
              <NavBar />
              <CategoryPage />
            </Route>
            <Route path="/category-create">
              <NavBar />
              <CategoryCreatePage />
            </Route>
            <Route path="/category-edit/:categoryCode">
              <NavBar />
              <CategoryEditPage />
            </Route>
            <Route path="/categories/:label">
              <NavBar />
              <CategoriesListPage />
            </Route>
            <Route path="/categories">
              <NavBar />
              <CategoriesListPage />
            </Route>
            <Route path="/categories-select">
              <NavBar />
              <CategoriesSelectPage />
            </Route>
            <Route path="/experiences">
              <NavBar />
              <ExperiencesPage />
            </Route>
            <Route path="/organizations">
              <NavBar />
              <BusinessReportPage />
            </Route>
            <Route path="/business-create">
              <NavBar />
              <BusinessCreatePage />
            </Route>
            <Route path="/business-edit/:businessCode">
              <NavBar />
              <BusinessEditPage />
            </Route>
            <Route path="/foryou">
              <NavBar />
              <ForYouPage />
            </Route>
            <Route path="/search/users">
              <NavBar />
              <UserResultsPage />
            </Route>
            <Route path="/terms-and-conditions">
              <NavBar />
              <TermsPage />
            </Route>
            <Route path="/privacy">
              <NavBar />
              <PrivacyPage />
            </Route>
            <Route path="/not-found">
              <NotFoundPage />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
};
